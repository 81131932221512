import { FC } from 'react';
import CheckIcon from '../icon/CheckIcon';
import Tooltip from '../Tooltip';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

type RadioButtonProps = {
  value: boolean;
  label: string;
  multi?: boolean;
  description?: string;
  className?: string;
  onChange?: (newValue: boolean) => void;
  size?: 'small' | 'normal';
  role?: string;
};

const RadioButton: FC<RadioButtonProps> = (props) => {
  const { value, label, multi, onChange, description, className, size, role } = props;

  const onClick = () => {
    onChange && onChange(!value);
  };

  return (
    <div
      {...mouseAndKeyboardCallbackProps(onClick)}
      className={`my-2 ${className} truncate`}
      data-cy="radio-button"
      tabIndex={0}
      role={role}
      aria-checked={value}
    >
      <div className="flex cursor-pointer select-none items-center" data-testid="radio-button">
        <svg
          className={`mr-2 inline-flex h-${size === 'small' ? '5' : '6'} w-${size === 'small' ? '5' : '6'}`}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="-1" y="1" width="14" height="14" rx="7" transform="matrix(-1 0 0 1 14 0)" stroke="#3E2234" strokeWidth="1.5" />
          {value && !multi && <rect width="8" height="8" rx="4" transform="matrix(-1 0 0 1 12 4)" fill="#3E2234" />}
          {value && multi && <CheckIcon className={`text-primary-1 h-4 w-4`} data-cy="check" />}
        </svg>

        <div className="truncate">
          <Tooltip text={label} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} data-cy="label" className="truncate">
                <span className={` ${description !== undefined ? 'text-black' : ''}`}>{label}</span>
              </div>
            )}
          </Tooltip>
          <Tooltip text={description} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="text-color-on-first-primary-1 truncate font-light">
                {description}
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default RadioButton;
